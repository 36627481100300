import { ArrowLeft, ArrowRight } from 'app/assets/icons';
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { ApolloConsumer } from '@apollo/client';
import { Link } from 'react-router-dom';
import useAccountActions from 'app/services/auth/useAccountActions';

const IconWithMenu = ({
  iconAndMenu,
  toggleIconMenu,
  setActionsEl,
  actionsEl,
}) => {
  const { logout } = useAccountActions();
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);
  const [toggleIcon, setToggleIcon] = useState(false);

  useEffect(() => {
    if (actionsEl === null) {
      setToggleIcon(false);
    } else {
      setToggleIcon(true);
    }
  }, [actionsEl]);

  const onToggleDrawer = (e) => {
    toggleIconMenu();
    setActionsEl(e.currentTarget);
  };

  return (
    <ApolloConsumer>
      {(client) => (
        <Grid
          container
          justifyContent="flex-end"
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <IconButton
              aria-controls="simple-menu-help"
              aria-haspopup="true"
              onClick={(e) => setMenuAnchorElement(e.currentTarget)}
              color="inherit"
              size="large"
            >
              {iconAndMenu.icon}
            </IconButton>
            <Menu
              id="simple-menu-help"
              anchorEl={menuAnchorElement}
              keepMounted
              open={Boolean(menuAnchorElement)}
              onClose={() => setMenuAnchorElement(null)}
            >
              {iconAndMenu.menuData.map((menuItem, i) => {
                switch (menuItem.type) {
                  case 'menu_item':
                    return (
                      <Link
                        to={menuItem.url}
                        key={i}
                        style={{ color: 'black' }}
                      >
                        <MenuItem
                          onClick={() => setMenuAnchorElement(null)}
                          key={i}
                        >
                          <IconButton
                            sx={{
                              p: '0px',
                              mr: 2,
                            }}
                            onClick={(e) =>
                              setMenuAnchorElement(e.currentTarget)
                            }
                          >
                            {menuItem.icon}
                          </IconButton>
                          <Typography
                            sx={{ fontSize: '16px', fontWeight: '600' }}
                          >
                            {menuItem.text}
                          </Typography>
                        </MenuItem>
                      </Link>
                    );
                  case 'functional_menu_item':
                    return (
                      <MenuItem
                        onClick={(e) => {
                          onToggleDrawer(e);
                        }}
                        aria-controls="company-switch-actions"
                        aria-haspopup="true"
                        key={i}
                      >
                        <IconButton
                          sx={{
                            p: '0px',
                            mr: 2,
                          }}
                          onClick={(e) => setMenuAnchorElement(e.currentTarget)}
                        >
                          {menuItem.icon}
                        </IconButton>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {menuItem.text}
                        </Typography>
                        {menuItem.leftIcon && (
                          <>
                            {toggleIcon ? (
                              <ArrowRight open={false} onClick={() => {}} />
                            ) : (
                              <ArrowLeft open={false} onClick={() => {}} />
                            )}
                          </>
                        )}
                      </MenuItem>
                    );
                  case 'logout_menu_item':
                    return (
                      <MenuItem
                        onClick={async () => {
                          await logout(client);
                        }}
                        key={i}
                      >
                        <IconButton
                          sx={{
                            p: '0px',
                            mr: 2,
                          }}
                          onClick={(e) => setMenuAnchorElement(e.currentTarget)}
                        >
                          {menuItem.icon}
                        </IconButton>
                        <Typography
                          sx={{ fontSize: '16px', fontWeight: '600' }}
                        >
                          {menuItem.text}
                        </Typography>
                      </MenuItem>
                    );
                  case 'divider':
                    return <Divider key={i} />;
                  default:
                    return () => {
                      console.log('default');
                    };
                }
              })}
            </Menu>
          </Grid>
        </Grid>
      )}
    </ApolloConsumer>
  );
};

export default IconWithMenu;
